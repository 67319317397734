import { AssetType } from "@iventis/domain-model/model/assetType";
import QueryString from "qs";
import { getSignedUrl } from "./cdn";

const urlsToExcludeCredentials = [
    "https://cdn.iventis.co.uk",
    "https://cdn-restricted.iventis.co.uk",
    "https://cdn.iventis.com",
    "https://cdn-dev.iventis.com",
    "https://cdn.iventis.sa.com",
    "https://cdn-dev.iventis.sa.com",
    "https://api.maptiler.com",
    "https://api.mapbox.com",
    "https://2.aerial.maps.ls.hereapi.com",
    "https://a.tiles.mapbox.com",
    "https://b.tiles.mapbox.com",
    "https://ecn.t0.tiles.virtualearth.net",
    "https://ecn.t1.tiles.virtualearth.net",
    "https://ecn.t2.tiles.virtualearth.net",
    "https://ecn.t3.tiles.virtualearth.net",
    "https://api.nearmap.com/",
    "https://tile.googleapis.com/",
    "https://tiles.basemaps.linz.govt.nz",
    "https://maps.six.nsw.gov.au",
];
const urlsToExcludeAuthorization = [
    "https://cdn.iventis.co.uk",
    "https://cdn-restricted.iventis.co.uk",
    "https://cdn.iventis.com",
    "https://cdn-dev.iventis.com",
    "https://cdn.iventis.sa.com",
    "https://cdn-dev.iventis.sa.com",
    "https://api.maptiler.com",
    "https://api.mapbox.com",
    "https://api.os.uk",
    "https://2.aerial.maps.ls.hereapi.com",
    "https://api.nearmap.com/",
    "https://tile.googleapis.com/",
    "https://tiles.basemaps.linz.govt.nz",
    "https://maps.six.nsw.gov.au",
];
const restrictedCdnUrls = [
    "https://cdn-restricted.iventis.co.uk",
    "https://cdn.iventis.com",
    "https://cdn-dev.iventis.com",
    "https://cdn.iventis.sa.com",
    "https://cdn-dev.iventis.sa.com",
];

export const bustTileCache = (tileUrls: string[]) =>
    tileUrls.map((tileUrl) => {
        const [urlBody, queryString] = tileUrl.split("?");
        const queryStringParsed = QueryString.parse(queryString || "");
        const cacheBustedQueryString = QueryString.stringify({ ...queryStringParsed, bustMe: new Date().getMilliseconds() });
        return `${urlBody}?${cacheBustedQueryString}`;
    });

export const transformRequest = (url, signatures, authorizationToken = undefined, projectId?: string) => {
    let signedUrl: string = url;
    let urlObject: URL;
    let headers = {};
    // No need to add signature for map icons as we handle that separately
    if (
        restrictedCdnUrls.some((restrictedCdnUrl) => url.toLowerCase().startsWith(restrictedCdnUrl)) &&
        !(url.includes(AssetType.MapIcon) || url.includes(AssetType.ProfileImage))
    ) {
        signedUrl = getSignedUrl(url, signatures);
    }
    if (!url.startsWith("/")) {
        urlObject = new URL(signedUrl);
        if (urlObject.hostname === "api.os.uk") urlObject.searchParams.append("srs", "3857");
    }
    if (authorizationToken && projectId && !urlsToExcludeAuthorization.some((u) => url.toLowerCase().startsWith(u))) {
        headers = { authorization: `Bearer ${authorizationToken}`, "project-id": projectId };
    }
    return {
        headers,
        url: urlObject ? urlObject.toString() : signedUrl,
        credentials: (urlsToExcludeCredentials.some((u) => url.toLowerCase().startsWith(u)) ? "same-origin" : "include") as "same-origin" | "include",
    };
};
